<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-0 px-0'"
    style="margin-bottom: 50px; margin-top: 80px"
  >
    <v-row no-gutters justify="center" class="mt-12 black--text">
      <v-col v-bind="main_card_width">
        <v-card color="transparent" class="pa-2">
          <v-card-text>
            <v-row class="rounded_row_white black--text pa-2 pb-12">
              <!-- <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.smAndDown">
                <v-img
                  class="service_image_position"
                  :src="require('@/assets/imgs/webdesign.svg')"
                  alt="Icona assistenza"
                  contain
                  style=""
                  max-width="200"
                  :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                ></v-img>
              </v-col> -->
              <v-col cols="12" offset-sm="1" sm="10" class="pt-8">
                <h1 class="service_title" style="width: 100% !important">
                  Tipologie di siti Web
                </h1>
                <!-- <div class="small_screen_service_image">
                  <v-img
                    v-if="$vuetify.breakpoint.smAndDown"
                    :src="require('@/assets/imgs/webdesign.svg')"
                    alt="Icona assistenza"
                    class="mt-2 mb-6"
                    contain
                    max-width="200"
                    :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                  ></v-img>
                </div> -->
                <h2 class="service_subtitle">Siti Web Statici</h2>

                <p>
                  I siti web statici, conosciuti anche come Web 1.0, sono
                  caratterizzati da un insieme di pagine tra loro collegate i
                  cui contenuti possono essere solamente consultati dagli
                  utlizzatori senza alcuna forma di interazione.
                </p>
                <h3>Vantaggi:</h3>
                <ul>
                  <li><h4>Sviluppo più veloce ed economico</h4></li>
                  <p>
                    Trattandosi di pagine statiche non è presente alcuna
                    attività di sviluppo lato server e questo significa
                    concentrarsi esclusivamente sui contenuti e l'impostazione
                    grafica escludendo lo sviluppo delle logiche applicative
                  </p>
                  <li><h4>Costi di hosting ridotti</h4></li>
                  <p>
                    Non essendo necessaria l'esecuzione di alcun codice ci si
                    può rivolgere a servizi di hosting dai costi più contenuti
                  </p>
                </ul>

                <h3>Svantaggi:</h3>
                <ul>
                  <li><h4>Aggiornamento</h4></li>
                  <p>
                    L'attività di aggiornamento di un sito statico non può
                    essere eseguita in autonomia in quanto prevede la modifica
                    diretta del codice HTML che compone le pagine e deve quindi
                    essere eseguita da un webmaster
                  </p>
                  <li><h4>Funzionalità limitate</h4></li>
                  <p>
                    I siti Web statici, non permettendo l'interazione con
                    l'utilizzatore, sono un canale di comunicazione
                    monodirezionale
                  </p>
                </ul>

                <h3>Quando utilizzare un sito Web statico</h3>
                <p>
                  I siti web statici sono indicati per pubblicazioni di piccola
                  entità dove i contenuti non variano frequentemente e dove non è
                  richiesta alcuna interazione con l'utente.
                  <br />
                  Sono adatti per siti informativi realtivi a servizi, attività
                  ed eventi o per offrire promozioni.
                </p>

                <h2 class="mt-12 service_subtitle">Siti Web Dinamici</h2>
                <p>
                  I siti web dinamici utilizzano informazioni e contenuti che
                  vengono salvati e prelevati da un database.<br />Si
                  definiscono dinamici poichè le pagine che li compongono in
                  realtà non contengono dati ma rappresentano solo lo scheletro
                  che verrà riempito di volta in volta.
                </p>
                <h3>Vantaggi:</h3>
                <ul>
                  <li><h4>Facilita di amministrazione</h4></li>
                  <p>
                    L'attività di aggiornamento dei contenuti avviene attraverso
                    interfacce di gestione come editor di testi che non
                    richiedono nozioni di programmazione
                  </p>
                </ul>
                <ul>
                  <li><h4>Interazione utente</h4></li>
                  <p>
                    Gli utenti possono interagire con i contenuti, ad esempio
                    compilando un modulo di contatto, acquistando prodotti o
                    iscrivendosi alla newsletter
                  </p>
                </ul>

                <h3>Svantaggi:</h3>
                <ul>
                  <li><h4>Costi hosting</h4></li>
                  <p>
                    Poichè i siti web dinamici richiedono l'esecuzione di codice
                    di programmazione lato server necessitano di hosting su
                    server dai costi e dalle perfomance più elevate
                  </p>
                </ul>
                <ul>
                  <li><h4>Sviluppo e manutenzione</h4></li>
                  <p>
                    Lo sviluppo di siti dinamici prevede una fase di sviluppo,
                    layout e di configurazione dell'ambiente database e un piano
                    di costante manutenzione e aggiornamento per le
                    vulnerabilità di sicurezza
                  </p>
                </ul>

                <h3>Quando utilizzare un sito Web dinamico</h3>
                <p>
                  I siti web dinamici sono ideali quando si vuole dare la
                  possibilità agli utenti di effettuare acquisti (ecommerce),
                  raccogliere contatti con form di iscrizione o quando i
                  contenuti debbano essere aggiornati con frequenza e in
                  autonomia.
                </p>
              </v-col>

              <v-col cols="12" class="text-center">
                <v-card
                  small
                  text
                  color="white black--text"
                  @click="$router.go(-1)"
                  flat
                >
                  <v-card-actions>
                    <v-spacer />
                    Torna indietro
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-col>

              <!-- <v-col cols="12" class="text-center">
                <v-card
                  small
                  text
                  color="white black--text"
                  :to="{ name: 'Sviluppo_SitiWeb' }"
                  flat
                >
                  <v-card-actions>
                    <v-spacer />
                    Vai a sviluppo siti Web
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Tipi di siti Web",
    meta: [
      {
        name: "description",
        content:
          "Scopri le differenze tra siti Web statici e siti Web Dinamici",
      },
      {
        name: "robots",
        content: "index",
      },
    ],
  },
};
</script>